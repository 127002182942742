const formatCurrency = (value) => {
  if (!value) return "-- N/A --";
  return `$${value.toFixed(2)}`;
};
function getOveragePrice(OverageObject) {
  if (!OverageObject[0]) return null;
  return formatCurrency(OverageObject[0].Cost);
}

function handleConstructAddress(address) {
  if (!address) return "N/A";
  return `${address.Address} ${address.City} ${address.ZipCode}`;
}
function getRegularPrice(Service, period) {
  let lowerprice;
  Service.forEach((service) => {
    if (service.Frequency === period) {
      service.Regular.forEach((regular) => {
        if (lowerprice === undefined || regular.Cost < lowerprice) {
          lowerprice = regular.Cost;
        }
      });
    }
  });
  return lowerprice !== undefined ? formatCurrency(lowerprice) : "N/A";
}
export function setModelToYards(yardsList) {
  return (
    yardsList &&
    yardsList.map((yards) => {
      return {
        cod: yards.YardId.idCosmos ? yards.YardId.idCosmos : "N/A",
        yardName: yards.YardId.CodeName ? yards.YardId.CodeName : "N/A",
        status: yards.SpotsAvailable ? "Active" : "Not Active",
        billing_address: yards.AddressYard
          ? handleConstructAddress(yards.AddressYard)
          : "N/A",

        spots: yards.SpotsAvailable ? yards.SpotsAvailable : "N/A",

        price_daily: yards.Services
          ? getRegularPrice(yards.Services, "Daily")
          : "N/A",
        over_price_daily: yards.Service?.Overage
          ? formatCurrency(getOveragePrice(yards.Service.Overage))
          : "N/A",
        price_monthly: yards.Services
          ? getRegularPrice(yards.Services, "Monthly")
          : "N/A",
        over_price_monthly: yards.Service?.Overage
          ? formatCurrency(getOveragePrice(yards.Service.Overage))
          : "N/A",
        yardObject: yards,
      };
    })
  );
}
