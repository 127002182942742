// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYard-Title-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5%;
}

body .OwnerYard-Title-title {
    font-size: 50px;
    margin: 0;
    padding: 0;
    font-weight: 900;
    color: 191919000;
}

body .OwnerYard-Title-subtitle {
    font-size: 20px;
    margin: 0;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/containers/Title/Title.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,UAAU;AACd","sourcesContent":["body .OwnerYard-Title-container {\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    padding: 5%;\n}\n\nbody .OwnerYard-Title-title {\n    font-size: 50px;\n    margin: 0;\n    padding: 0;\n    font-weight: 900;\n    color: 191919000;\n}\n\nbody .OwnerYard-Title-subtitle {\n    font-size: 20px;\n    margin: 0;\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
