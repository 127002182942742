// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYards-YardsSummary-grid-circular-progress {
    display: flex;
    justify-content: center;
    height: 40vh;
    align-items: center;
}


body .OwnerYards-YardsSummary-circular-progress{
    color: #fc4817;
    scale: 5;
}`, "",{"version":3,"sources":["webpack://./src/containers/YardsSummary/YardsSummary.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;;;AAGA;IACI,cAAc;IACd,QAAQ;AACZ","sourcesContent":["body .OwnerYards-YardsSummary-grid-circular-progress {\n    display: flex;\n    justify-content: center;\n    height: 40vh;\n    align-items: center;\n}\n\n\nbody .OwnerYards-YardsSummary-circular-progress{\n    color: #fc4817;\n    scale: 5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
