import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./Title.css";

export const Title = () => {
  return (
    <section className="OwnerYard-Title-container">
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="OwnerYard-Title-title">
            Your Own Yards
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          {" "}
          <Typography className="OwnerYard-Title-subtitle">
            Create or manage your yards with specific details
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};
