import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TextField, Typography, Divider, Button } from "@mui/material";
import React from "react";
import "./FillInformation.css";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
const FillInformation = ({
  payload,
  setPayload,
  validateStepOne,
  typeOfTransaction,
}) => {
  const [newSpots, setNewSpots] = React.useState({
    value: 1,
    validation: false,
  });
  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const deleteFeature = (index) => {
    if (payload.YardInformation.length === 1) return;
    const newItems = [...payload.YardInformation];
    newItems.splice(index, 1);
    setPayload({
      ...payload,
      YardInformation: newItems,
    });
  };

  const contactNameRegex = /^[a-zA-Z\s]*$/;
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="OwnerYard-FillInformation-title">
            Register Your Yard
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          {" "}
          <Typography className="OwnerYard-FillInformation-features-title">
            Please fill information with details needed
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            size="small"
            fullWidth
            error={payload.Name?.length === 0 && validateStepOne}
            id="outlined-basic"
            label="Yard Name"
            disabled={typeOfTransaction === "edit"}
            variant="outlined"
            className="OwnerYard-FillInformation-textfield-name"
            value={payload.Name ? payload.Name : ""}
            onChange={(e) => {
              const inputValue = e.target.value;
              const capitalizedValue = capitalize(inputValue);

              setPayload({
                ...payload,
                Name: capitalizedValue,
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Display Name"
            error={payload.DisplayName?.length === 0 && validateStepOne}
            variant="outlined"
            className="OwnerYard-FillInformation-textfield-name"
            value={payload.DisplayName ? payload.DisplayName : ""}
            onChange={(e) => {
              const inputValue = e.target.value;
              const capitalizedValue = capitalize(inputValue);

              setPayload({
                ...payload,
                DisplayName: capitalizedValue,
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Address Line"
            variant="outlined"
            error={payload.Address?.Address?.length === 0 && validateStepOne}
            value={payload.Address?.Address ? payload.Address.Address : ""}
            onChange={(e) => {
              setPayload({
                ...payload,
                Address: {
                  ...payload.Address,
                  Address: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="City/Disctrict"
            variant="outlined"
            error={payload.Address?.City?.length === 0 && validateStepOne}
            value={payload.Address?.City ? payload.Address.City : ""}
            onChange={(e) => {
              const CapilatizedValue = capitalize(e.target.value);
              setPayload({
                ...payload,
                Address: {
                  ...payload.Address,
                  City: CapilatizedValue,
                },
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="State"
            variant="outlined"
            error={payload.Address?.State?.length === 0 && validateStepOne}
            value={payload.Address?.State ? payload.Address.State : ""}
            onChange={(e) => {
              const CapilatizedValue = capitalize(e.target.value);
              setPayload({
                ...payload,
                Address: {
                  ...payload.Address,
                  State: CapilatizedValue,
                },
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Postal Code"
            variant="outlined"
            error={payload.Address?.ZipCode?.length === 0 && validateStepOne}
            value={payload.Address?.ZipCode ? payload.Address.ZipCode : ""}
            onChange={(e) => {
              const parsedValue = parseInt(e.target.value);

              if (isNaN(parsedValue) && e.target.value !== "") {
                return;
              }

              setPayload({
                ...payload,
                Address: {
                  ...payload.Address,
                  ZipCode: parsedValue,
                },
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Yard Description"
            multiline={true}
            rows={2}
            error={payload.Description?.length === 0 && validateStepOne}
            variant="outlined"
            value={payload.Description ? payload.Description : ""}
            onChange={(e) => {
              setPayload({
                ...payload,
                Description: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="OwnerYard-FillInformation-features-title">
            Yard Features
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} container spacing={2}>
          {payload.YardInformation.length > 0 &&
            payload.YardInformation.map((item, index) => {
              return (
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                  <TextField
                    fullWidth
                    size="small"
                    key={index}
                    id="outlined-basic"
                    variant="outlined"
                    value={item ? item : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() => deleteFeature(index)}
                          position="end"
                          disabled={payload.YardInformation.length === 1}
                          className="OwnerYards-FillInformation-delete-feature"
                        >
                          <ClearIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const newItems = [...payload.YardInformation]; // Copia del arreglo
                      newItems[index] = capitalize(e.target.value);
                      setPayload({
                        ...payload,
                        YardInformation: newItems,
                      });
                    }}
                  />
                </Grid>
              );
            })}

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="OwnerYards-FillInformation-Grid-Button-Add-Feature"
          >
            <Button
              variant="text"
              className="OwnerYards-FillInformation-Button-Add-Feature"
              onClick={() => {
                setPayload({
                  ...payload,
                  YardInformation: [...payload.YardInformation, ""],
                });
              }}
              startIcon={<AddIcon />}
            >
              Add New Feature
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          {" "}
          <TextField
            size="small"
            value={payload.SpotsAvailable}
            fullWidth
            variant="outlined"
            type="number"
            error={newSpots.value === "" || newSpots.value < 0}
            inputProps={{
              className: "OwnerYards-FillInformation-textfield-spots",
            }}
            placeholder="0"
            onChange={(e) => {
              const value = e.target.value;
              if (value === "" || parseInt(value) < 0) {
                setNewSpots({ ...newSpots, validation: false, value: 0 });
                setPayload({
                  ...payload,
                  SpotsAvailable: 0,
                });
                return;
              }
              setNewSpots({
                ...newSpots,
                validation: true,
                value: parseInt(value),
              });
              setPayload({
                ...payload,
                SpotsAvailable: parseInt(value),
              });
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="OwnerYard-FillInformation-grid-spots-text"
        >
          <Typography className="OwnerYard-FillInformation-spots-text">
            Spots Available in Yard
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FillInformation;
