// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYards-DeleteYard-title {
    font-weight: bold;
    color: #1578fc;
}

body .OwnerYards-DeleteYard-paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 50vh;
    height: 50vh;
}

body .OwnerYards-DeleteYard-description {
    font-weight: 500;
    color: #4a4a4a;
}

body .OwnerYards-DeleteYard-title-display-flex {
    display: flex;

}

body .OwnerYards-DeleteYard-description-margin {
    margin-left: 10px;
}

body .OwnerYards-DeleteYard-circular-progress {
    scale: 3;
    color: #fc4817;
}

body .OwnerYards-DeleteYard-circular-progress-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

body .OwnerYards-DeleteYard-button-cancel {
    background-color: #22224F;
    font-weight: bold;
    color: white;
    font-size: 100%;
    border-radius: 10px;
    margin: 10px 0px 10px 0px;
}

body .OwnerYards-DeleteYard-button-delete {
    background-color: red;
    border-radius: 10px;
    color: white;
    font-size: 100%;
    margin: 10px 0px 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/containers/DeleteYardDialog/DeleteYardDialog.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;;AAEjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":["body .OwnerYards-DeleteYard-title {\n    font-weight: bold;\n    color: #1578fc;\n}\n\nbody .OwnerYards-DeleteYard-paper {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    min-height: 50vh;\n    height: 50vh;\n}\n\nbody .OwnerYards-DeleteYard-description {\n    font-weight: 500;\n    color: #4a4a4a;\n}\n\nbody .OwnerYards-DeleteYard-title-display-flex {\n    display: flex;\n\n}\n\nbody .OwnerYards-DeleteYard-description-margin {\n    margin-left: 10px;\n}\n\nbody .OwnerYards-DeleteYard-circular-progress {\n    scale: 3;\n    color: #fc4817;\n}\n\nbody .OwnerYards-DeleteYard-circular-progress-box {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\nbody .OwnerYards-DeleteYard-button-cancel {\n    background-color: #22224F;\n    font-weight: bold;\n    color: white;\n    font-size: 100%;\n    border-radius: 10px;\n    margin: 10px 0px 10px 0px;\n}\n\nbody .OwnerYards-DeleteYard-button-delete {\n    background-color: red;\n    border-radius: 10px;\n    color: white;\n    font-size: 100%;\n    margin: 10px 0px 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
