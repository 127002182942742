import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import "./DeleteYardDialog.css";
import Cookies from "universal-cookie";
import { deleteYard } from "../../services/YardServices";
import { useSnackBar } from "../../context/SnackBarContext";

const DeleteYardDialog = ({ openDeleteModal, onClose, yardInfo }) => {
  const { handleRenderSnackBar } = useSnackBar();
  const [deletingYard, setDeletingYard] = React.useState(false);
  const handleDeleteYard = () => {
    setDeletingYard(true);
    const cookies = new Cookies();

    deleteYard(
      yardInfo.YardId.idCosmos,
      cookies.get("sessionCosmos").scac_code,
      cookies.get("sessionCosmos").email[0]
    )
      .then((data) => {
        if (data.success) {
          onClose();
          setDeletingYard(false);
          handleRenderSnackBar(data.message, "success");
          window.dispatchEvent(new CustomEvent("OwnerYardsFetchData"));
          return;
        }
        setDeletingYard(false);
        handleRenderSnackBar(data.message, "error");
      })
      .catch((error) => {
        setDeletingYard(false);
        handleRenderSnackBar(error, "error");
      });
  };
  return (
    <>
      <Dialog
        open={openDeleteModal}
        onClose={onClose}
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          className: "OwnerYards-DeleteYard-paper",
        }}
      >
        {deletingYard ? (
          <Box className="OwnerYards-DeleteYard-circular-progress-box">
            <CircularProgress
              thickness={5}
              className="OwnerYards-DeleteYard-circular-progress"
            />
          </Box>
        ) : (
          <>
            <DialogTitle>Delete Yard</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure to delete this Yard?
                <List>
                  {yardInfo ? (
                    <>
                      <ListItem>
                        <Typography className="OwnerYards-DeleteYard-title">
                          Yard Display Name:
                          <Typography className="OwnerYards-DeleteYard-description">
                            {yardInfo.YardDisplayName}
                          </Typography>
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography className="OwnerYards-DeleteYard-title OwnerYards-DeleteYard-title-display-flex">
                          Address:
                          <Typography className="OwnerYards-DeleteYard-description OwnerYards-DeleteYard-description-margin">
                            {yardInfo.AddressYard.Address +
                              " " +
                              yardInfo.AddressYard.City +
                              " " +
                              yardInfo.AddressYard.State +
                              " " +
                              yardInfo.AddressYard.ZipCode}
                          </Typography>
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography className="OwnerYards-DeleteYard-title OwnerYards-DeleteYard-title-display-flex">
                          Spots:
                          <Typography className="OwnerYards-DeleteYard-description OwnerYards-DeleteYard-description-margin">
                            {yardInfo.SpotsAvailable}
                          </Typography>
                        </Typography>
                      </ListItem>
                    </>
                  ) : (
                    <Typography>No Yard data available.</Typography>
                  )}
                </List>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                className="OwnerYards-DeleteYard-button-cancel"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="OwnerYards-DeleteYard-button-delete"
                variant="contained"
                onClick={(e) => handleDeleteYard()}
              >
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default DeleteYardDialog;
