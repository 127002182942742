import { axiosInstance } from "./appInsights";

const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};

export const getYardsByScac = async (ScacCode) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_YARDS_BY_SCAC,
      params: { ScacCode },
      method: "GET",
      nameEvent: "OwnerYards-getYardsByScac", // Agrega el nombre del evento aquí
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error("Error in getYardsByScac:", error);
    return [];
  }
};

export const createYard = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_CREATE_YARD,
      data: payload,
      method: "POST",
      nameEvent: "OwnerYards-createYard", // Agrega el nombre del evento aquí
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error("Error in createYard:", error);
    return error;
  }
};

export const updateYard = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_UPDATE_YARD,
      data: payload,
      method: "POST",
      nameEvent: "OwnerYards-updateYard", // Agrega el nombre del evento aquí
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error("Error in updateYard:", error);
    return error;
  }
};

export const deleteYard = async (YardId, ScacCode, userRequesting) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_DELETE_YARD,
      data: { YardId, ScacCode, userRequesting },
      method: "POST",
      nameEvent: "OwnerYards-deleteYard", // Agrega el nombre del evento aquí
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error("Error in deleteYard:", error);
    return error;
  }
};
