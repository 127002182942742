import React from "react";
import {
  Dialog,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Cookies from "universal-cookie";
import FillInformation from "./FillInformationStep/FillInformation";
import PeriodDailySettings from "./PeriodSettings/PeriodDailySettings";
import "./Modal.css";
import { useSnackBar } from "../../context/SnackBarContext";
import { createYard, updateYard } from "../../services/YardServices";

const CreateYardModal = () => {
  const [state, setState] = React.useState(false);
  const cookies = new Cookies();
  const { handleRenderSnackBar } = useSnackBar();
  const [typeOfTransaction, setTypeOfTransaction] = React.useState("");
  const [productIsAdded, setProductIsAdded] = React.useState(false);
  const [creatingObject, setCreatingObject] = React.useState(false);
  const [validateStepOne, setValidateStepOne] = React.useState(false);
  const [stepSelected, setSetpSelected] = React.useState(0);
  const [editPayloadObject, setEditPayloadObject] = React.useState({});
  const [payload, setPayload] = React.useState({
    OwnerScacCode: "",
    Name: "",
    DisplayName: "",
    UserRequest: "",
    Address: {
      Address: "",
      ZipCode: "",
      City: "",
      State: "",
    },
    SpotsAvailable: 1,
    Description: "",
    YardInformation: [""],
    Services: [
      {
        Id: "Daily",
        prices: [],
      },
      {
        Id: "Monthly",
        prices: [],
      },
    ],
  });
  const [activeColumns, setActiveColumns] = React.useState({
    Daily: false,
    Monthly: false,
    Overage: false,
  });
  const [productsAdded, setProductsAdded] = React.useState([]);

  const listOfProducts = [
    {
      Description: "20' 40' 45'",
      Type: "20-40-45",
    },
    {
      Description: "53'",
      Type: "53",
    },
  ];

  function validateStepOneByPayload() {
    if (!payload.Name) return false;
    else if (!payload.DisplayName) return false;
    else if (!payload.Address.Address) return false;
    else if (!payload.Address.ZipCode) return false;
    else if (!payload.Address.City) return false;
    else if (!payload.Address.State) return false;
    else if (!payload.Description) return false;
    else if (payload.SpotsAvailable === "") return false;
    else if (payload.YardInformation[0] === "" || !payload.YardInformation[0])
      return false;

    return true;
  }

  function handleBack() {
    setSetpSelected((prevActiveStep) => prevActiveStep - 1);
  }

  function CloseModal() {
    cleanPayload();
    setState(false);
    setProductsAdded([]);
  }

  function handleNext() {
    if (stepSelected === 0) {
      setSetpSelected((prevActiveStep) => prevActiveStep + 1);
      const validation = validateStepOneByPayload();
      if (!validation) {
        handleRenderSnackBar(
          "All fields are required - (You must add at least one feature)",
          "error"
        );
        setSetpSelected((prevActiveStep) => prevActiveStep - 1);
        setValidateStepOne(true);
        return;
      }
    }
    if (stepSelected === 3) {
      setSetpSelected(2);
      handleCreateObjectToCreateYard();
    }
    if (stepSelected === 1) {
      setSetpSelected((prevActiveStep) => prevActiveStep + 1);
      handleCreateObjectToCreateYard();
    }
  }

  function cleanPayload() {
    setPayload({
      OwnerScacCode: "",
      Name: "",
      DisplayName: "",
      UserRequest: "",
      Address: {
        Address: "",
        ZipCode: "",
        City: "",
        State: "",
      },
      Description: "",
      YardInformation: [""],
      SpotsAvailable: 1,
      Services: [
        {
          Id: "Daily",
          prices: [],
        },
        {
          Id: "Monthly",
          prices: [],
        },
      ],
    });
  }
  function handleCreateObjectToCreateYard() {
    setCreatingObject(true);
    const sessionCosmos = cookies.get("sessionCosmos");
    const listOfPricesDaily = [];
    const listOfPricesMonthly = [];

    let allProductsHavePrice = true;

    productsAdded.forEach((product) => {
      let onePriceAssigned = false;
      if (product.Daily.Cost !== "") {
        listOfPricesDaily.push(product.Daily);
        onePriceAssigned = true;
      }
      if (product.Monthly.Cost !== "") {
        listOfPricesMonthly.push(product.Monthly);
        onePriceAssigned = true;
      }
      if (product.Overage.Cost !== "") {
        listOfPricesMonthly.push(product.Overage);
        onePriceAssigned = true;
      }
      if (!onePriceAssigned) {
        allProductsHavePrice = false;
      }
    });

    if (!allProductsHavePrice) {
      setCreatingObject(false);
      handleBack();
      handleRenderSnackBar(
        "You must assign at least one price to each product",
        "error"
      );
      return;
    }

    let listOfFeatures = [];
    payload.YardInformation.map((feature) => {
      if (feature.length === 0) {
        return;
      }
      listOfFeatures.push(feature);
    });

    const objectToCreate = {
      // OwnerScacCode: "SNEA",
      // UserRequest: "revoa7@yahoo.com",
      OwnerScacCode: sessionCosmos.scac_code,
      UserRequest: sessionCosmos.email[0],
      DisplayName: payload.DisplayName,
      Name: payload.Name,
      Address: {
        Address: payload.Address.Address,
        ZipCode: payload.Address.ZipCode,
        City: payload.Address.City,
        State: payload.Address.State,
      },
      Description: payload.Description,
      YardInformation: listOfFeatures,
      SpotsAvailable: payload.SpotsAvailable,
      Services: [
        ...(listOfPricesDaily.length > 0
          ? [
              {
                Id: "Daily",
                prices: listOfPricesDaily,
              },
            ]
          : []),
        ...(listOfPricesMonthly.length > 0
          ? [
              {
                Id: "Monthly",
                prices: listOfPricesMonthly,
              },
            ]
          : []),
      ],
    };

    if (typeOfTransaction === "edit") {
      objectToCreate.id = editPayloadObject.YardId.idCosmos;
      updateYard(objectToCreate)
        .then((response) => {
          if (response.success) {
            handleRenderSnackBar("Yard updated successfully", "success");

            setSetpSelected(0);
            setCreatingObject(false);
            cleanPayload();
            CloseModal();
            window.dispatchEvent(new CustomEvent("OwnerYardsFetchData"));
          } else {
            handleRenderSnackBar(response.message, "error");
            setCreatingObject(false);
          }
        })
        .catch((error) => {
          handleRenderSnackBar(error, "error");
          setCreatingObject(false);
        });
    } else {
      createYard(objectToCreate)
        .then((response) => {
          if (response.success) {
            handleRenderSnackBar("Yard created successfully", "success");

            setSetpSelected(0);
            setCreatingObject(false);
            cleanPayload();
            CloseModal();
            window.dispatchEvent(new CustomEvent("OwnerYardsFetchData"));
          } else {
            handleRenderSnackBar(response.message, "error");
            setCreatingObject(false);
          }
        })
        .catch((error) => {
          handleRenderSnackBar(error, "error");
          setCreatingObject(false);
        });
    }
  }

  React.useEffect(() => {
    window.addEventListener("openModalYard", (event) => {
      if (event.detail.typeOfTransaction === "create") {
        setState(true);
        setSetpSelected(0);
        setTypeOfTransaction("create");
        setProductsAdded([
          {
            Daily: {
              Cost: "",
              Overage: false,
              Description: "",
              Type: "",
            },
            Monthly: {
              Cost: "",
              Overage: false,
              Description: "",
              Type: "",
            },
            Overage: { Cost: "", Overage: true, Description: "", Type: "" },
            prod: { Description: "", Type: "" },
          },
        ]);
      } else if (event.detail.typeOfTransaction === "edit") {
        setTypeOfTransaction("edit");
        setState(true);
        setSetpSelected(3);
        const yardItem = event.detail.yardItem;
        setPayload({
          OwnerScacCode: yardItem.OwnerScacCode,
          Name: yardItem.YardId.CodeName,
          DisplayName: yardItem.YardDisplayName,
          Address: {
            Address: yardItem.AddressYard.Address,
            ZipCode: yardItem.AddressYard.ZipCode,
            City: yardItem.AddressYard.City,
            State: yardItem.AddressYard.State,
          },
          Description: yardItem.Description,
          YardInformation: yardItem.pYardInformation,
          SpotsAvailable: yardItem.SpotsAvailable,
        });
        setEditPayloadObject(yardItem);
      }
    });
  }, []);
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={state}
        onClose={() => CloseModal()}
        PaperProps={{
          className: "OwnerYards-Modal-paper-props",
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          className: "OwnerYards-Modal-backdrop-props",
        }}
      >
        <Grid container spacing={2} className="OwnerYards-Modal-grid-container">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="OwnerYards-Modal-grid-item-setpcontent"
          >
            {stepSelected === 0 && (
              <FillInformation
                payload={payload}
                setPayload={setPayload}
                validateStepOne={validateStepOne}
              />
            )}
            {stepSelected === 1 && (
              <PeriodDailySettings
                payload={payload}
                setPayload={setPayload}
                activeColumns={activeColumns}
                setActiveColumns={setActiveColumns}
                productsAdded={productsAdded}
                setProductsAdded={setProductsAdded}
                listOfProducts={listOfProducts}
                setProductIsAdded={setProductIsAdded}
              />
            )}
            {stepSelected === 2 && (
              <Box className="OwnerYards-Modal-Box-CircularProgress">
                <CircularProgress
                  className="OwnerYards-Modal-CircularProgress"
                  thickness={5}
                />
              </Box>
            )}
            {stepSelected === 3 && (
              <>
                <Grid
                  container
                  spacing={2}
                  className="OwnerYards-Modal-grid-container"
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FillInformation
                      payload={payload}
                      typeOfTransaction={typeOfTransaction}
                      setPayload={setPayload}
                      validateStepOne={validateStepOne}
                    />{" "}
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <PeriodDailySettings
                      payload={payload}
                      setPayload={setPayload}
                      activeColumns={activeColumns}
                      setActiveColumns={setActiveColumns}
                      productsAdded={productsAdded}
                      setProductsAdded={setProductsAdded}
                      listOfProducts={listOfProducts}
                      setProductIsAdded={setProductIsAdded}
                      editPayloadObject={editPayloadObject}
                      typeOfTransaction={typeOfTransaction}
                      setEditPayloadObject={setEditPayloadObject}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box sx={{ width: "100%" }}>
              {stepSelected !== 3 && (
                <Stepper activeStep={stepSelected} alternativeLabel>
                  {[1, 2, 3].map((index) => (
                    <Step key={index}>
                      <StepLabel />
                    </Step>
                  ))}
                </Stepper>
              )}
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
            <Grid
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="OwnerYards-Modal-back-button-grid"
            >
              <Button
                disabled={
                  stepSelected === 0 || creatingObject
                    ? true
                    : false || stepSelected === 3
                }
                className="OwnerYards-Modal-back-button"
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="OwnerYards-Modal-continue-button-grid"
            >
              <Button
                disabled={
                  (stepSelected === 1 && !productIsAdded) || creatingObject
                    ? true
                    : false
                }
                className="OwnerYards-Modal-continue-button"
                onClick={() => handleNext()}
              >
                {stepSelected === 3 ? "Update" : "Continue"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default CreateYardModal;
