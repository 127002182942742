import { Typography, Paper, Divider, Button, IconButton } from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import "./YardCard.css";
import DeleteYardDialog from "../../containers/DeleteYardDialog/DeleteYardDialog";
const YardCard = ({ yard }) => {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  function handleOpenEditModal() {
    window.dispatchEvent(
      new CustomEvent("openModalYard", {
        detail: { typeOfTransaction: "edit", yardItem: yard.yardObject },
      })
    );
  }
  return (
    <>
      <Paper elevation={2} className="OwnerYards-YardCard-paper">
        <Grid container spacing={2}>
          <Grid xs={12} sm={8} md={9} lg={10} xl={10} container spacing={0}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="OwnerYards-YardCard-yard-name">
                {yard.yardName}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className="OwnerYards-YardCard-yard-divider" />
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              container
              spacing={2}
              columns={16}
            >
              <Grid xs={12} sm={12} md={4} lg={2} xl={2}>
                <Typography className="OwnerYards-YardCard-label">
                  Status
                </Typography>
                <Typography className="OwnerYards-YardCard-value">
                  {yard.status}
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={4} lg={4} xl={6}>
                <Typography className="OwnerYards-YardCard-label">
                  Billing Address
                </Typography>
                <Typography className="OwnerYards-YardCard-value">
                  {yard.billing_address}
                </Typography>
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={2} xl={2}>
                <Typography className="OwnerYards-YardCard-label">
                  # of Spots
                </Typography>
                <Typography className="OwnerYards-YardCard-value">
                  {yard.spots < 0 ? "0" : yard.spots}
                </Typography>
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={2} xl={2}>
                <Typography className="OwnerYards-YardCard-label">
                  Price/day
                </Typography>
                <Typography className="OwnerYards-YardCard-value">
                  {yard.price_daily}
                </Typography>
              </Grid>

              <Grid xs={12} sm={6} md={4} lg={2} xl={2}>
                <Typography className="OwnerYards-YardCard-label">
                  Price/month
                </Typography>
                <Typography className="OwnerYards-YardCard-value">
                  {yard.price_monthly}
                </Typography>
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={2} xl={2}>
                <Typography className="OwnerYards-YardCard-label">
                  Over.price./Month
                </Typography>
                <Typography className="OwnerYards-YardCard-value">
                  {yard.over_price_monthly}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={4}
            md={3}
            lg={2}
            xl={2}
            className="OwnerYards-YardCard-Buttons-Grid-Container"
          >
            <Button
              startIcon={<Edit />}
              className="OwnerYards-YardCard-Button-Edit"
              variant="contained"
              onClick={() => handleOpenEditModal()}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              className="OwnerYards-YardCard-Button-Delete"
              onClick={() => setOpenDeleteModal(true)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <DeleteYardDialog
          openDeleteModal={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          yardInfo={yard.yardObject}
        />
      </Paper>
    </>
  );
};

export default YardCard;
