// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYards-Modal-paper-props {
    border-radius: 16px;
    padding: 3% 3% 3% 3%;
    max-height: 75%;
    height: 75%;
}

body .OwnerYards-Modal-backdrop-props {
    backdrop-filter: blur(15px);
    background-color: #22224f4d;
}

body .OwnerYards-Modal-continue-button {
    background-color: #fc4817;
    border-radius: 10px;
    color: white;
    font-size: 100%;
    margin: 10px 0px 10px 0px;
}

body .OwnerYards-Modal-back-button {
    color: #22224F;
    font-weight: bold;
    font-size: 100%;
    margin: 10px 0px 10px 0px;
}

body .OwnerYards-Modal-continue-button:hover {
    background-color: #22224F;
    border-radius: 10px;
    color: white;
    font-size: 100%;
    margin: 10px 0px 10px 0px;
}

body .OwnerYards-Modal-continue-button-grid {
    display: flex;
    justify-content: flex-start;
}

body .OwnerYards-Modal-back-button-grid {
    display: flex;
    justify-content: flex-end;
}

body .OwnerYards-Modal-grid-item-setpcontent {
    min-height: 85%;
    display: flex;
}

body .OwnerYards-Modal-grid-container {
    min-height: 100%;
}

body .OwnerYards-Modal-Box-CircularProgress {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

body .OwnerYards-Modal-CircularProgress {
    color: #fc4817;
    scale: 5;
}`, "",{"version":3,"sources":["webpack://./src/containers/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,QAAQ;AACZ","sourcesContent":["body .OwnerYards-Modal-paper-props {\n    border-radius: 16px;\n    padding: 3% 3% 3% 3%;\n    max-height: 75%;\n    height: 75%;\n}\n\nbody .OwnerYards-Modal-backdrop-props {\n    backdrop-filter: blur(15px);\n    background-color: #22224f4d;\n}\n\nbody .OwnerYards-Modal-continue-button {\n    background-color: #fc4817;\n    border-radius: 10px;\n    color: white;\n    font-size: 100%;\n    margin: 10px 0px 10px 0px;\n}\n\nbody .OwnerYards-Modal-back-button {\n    color: #22224F;\n    font-weight: bold;\n    font-size: 100%;\n    margin: 10px 0px 10px 0px;\n}\n\nbody .OwnerYards-Modal-continue-button:hover {\n    background-color: #22224F;\n    border-radius: 10px;\n    color: white;\n    font-size: 100%;\n    margin: 10px 0px 10px 0px;\n}\n\nbody .OwnerYards-Modal-continue-button-grid {\n    display: flex;\n    justify-content: flex-start;\n}\n\nbody .OwnerYards-Modal-back-button-grid {\n    display: flex;\n    justify-content: flex-end;\n}\n\nbody .OwnerYards-Modal-grid-item-setpcontent {\n    min-height: 85%;\n    display: flex;\n}\n\nbody .OwnerYards-Modal-grid-container {\n    min-height: 100%;\n}\n\nbody .OwnerYards-Modal-Box-CircularProgress {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n}\n\nbody .OwnerYards-Modal-CircularProgress {\n    color: #fc4817;\n    scale: 5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
