import { Title } from "./containers/Title/Title";
import CreateYardButton from "./containers/CreateYard/CreateYardButton";
import NotificationAlert from "./components/SnackBar/Snackbar";
import YardSummary from "./containers/YardsSummary/YardsSummary";
import CreateYardModal from "./containers/Modal/Modal";
import { TableRowProvider } from "./context/TableRowContext";
import { SnackBarProvider } from "./context/SnackBarContext";
import "./root.css";
export default function Root() {
  return (
    <section className="OwnerYard-root-container">
      <section style={{ height: "100%" }}>
        <TableRowProvider>
          <SnackBarProvider>
            <Title />
            <CreateYardButton />
            <CreateYardModal />
            <NotificationAlert />
            <YardSummary />
          </SnackBarProvider>
        </TableRowProvider>
      </section>
    </section>
  );
}
