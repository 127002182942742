import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { Button } from "@mui/material";
import "./CreateYardButton.css";
const CreateYardButton = () => {
  function handleOpenModal() {
    window.dispatchEvent(
      new CustomEvent("openModalYard", {
        detail: { typeOfTransaction: "create" },
      })
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6} md={8} lg={9} xl={10}></Grid>
      <Grid xs={12} sm={6} md={4} lg={3} xl={2}>
        <Button
          fullWidth
          variant="contained"
          className="OwnerYard-CreateYardButton-button"
          onClick={handleOpenModal}
        >
          Create Yard
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateYardButton;
