// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYard-CreateYardButton-button{
        background-color: #fc4817;
        border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/containers/CreateYard/CreateYardButton.css"],"names":[],"mappings":"AAAA;QACQ,yBAAyB;QACzB,mBAAmB;AAC3B","sourcesContent":["body .OwnerYard-CreateYardButton-button{\n        background-color: #fc4817;\n        border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
