// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYard-root-container {
    padding: 50px 20% 5% 20%;
    height: fit-content;
    min-height: calc(100vh - 72px);
    background: #F2F5F7 0% 0% no-repeat padding-box;
}`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mBAAmB;IACnB,8BAA8B;IAC9B,+CAA+C;AACnD","sourcesContent":["body .OwnerYard-root-container {\n    padding: 50px 20% 5% 20%;\n    height: fit-content;\n    min-height: calc(100vh - 72px);\n    background: #F2F5F7 0% 0% no-repeat padding-box;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
