import React from "react";
import {
  Typography,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./PeriodDailySettings.css";
import AddIcon from "@mui/icons-material/Add";

const PeriodDailySettings = ({
  payload,
  setPayload,
  activeColumns,
  productsAdded,
  setProductsAdded,
  setActiveColumns,
  listOfProducts,
  setProductIsAdded,
  editPayloadObject,
  typeOfTransaction,
}) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleActivateAndDeactivateColumns(period) {
    let tempActiveColumns = activeColumns;

    for (let key in tempActiveColumns) {
      tempActiveColumns[key] = false;
    }
    tempActiveColumns[period] = true;
    setActiveColumns({ ...tempActiveColumns });
  }

  const handleAddItemToProductAdded = () => {
    if (productsAdded.length === listOfProducts.length) return;
    setProductsAdded((prevProductsAdded) => [
      ...prevProductsAdded,
      {
        Daily: {
          Cost: "",
          Overage: false,
          Description: "",
          Type: "",
        },
        Monthly: {
          Cost: "",
          Overage: false,
          Description: "",
          Type: "",
        },
        Overage: { Cost: "", Overage: true, Description: "", Type: "" },
        prod: {},
      },
    ]);
  };

  function handleEditProductAdded(index, value) {
    setProductsAdded((prevProductsAdded) => {
      const tempProducts = [...prevProductsAdded];
      tempProducts[index]["Daily"]["Type"] = value.Type;
      tempProducts[index]["Daily"]["Description"] = value.Description;
      tempProducts[index]["Monthly"]["Type"] = value.Type;
      tempProducts[index]["Monthly"]["Description"] = value.Description;
      tempProducts[index]["Overage"]["Type"] = value.Type;
      tempProducts[index]["Overage"]["Description"] = value.Description;
      tempProducts[index]["prod"] = value;
      return tempProducts;
    });
    setProductIsAdded(true);
  }

  function handleSetRegularPrice(index, value, period) {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      parsedValue = "";
    }
    setProductsAdded((prevProductsAdded) => {
      const tempProducts = [...prevProductsAdded];
      tempProducts[index][period]["Cost"] = parsedValue;
      return tempProducts;
    });
  }

  function handleSetOveragePrice(index, value) {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      parsedValue = "";
    }
    setProductsAdded((prevProductsAdded) => {
      const tempProducts = [...prevProductsAdded];
      tempProducts[index]["Overage"]["Cost"] = parsedValue;
      return tempProducts;
    });
  }

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    if (editPayloadObject) {
      const newListOfProductsAdded = editPayloadObject.Services.reduce(
        (acc, service) => {
          service.Regular.forEach((regular) => {
            const productExists = acc.some(
              (product) => product.prod.Type === regular.Type
            );
            if (!productExists) {
              acc.push({
                Daily: {
                  Cost: "",
                  Overage: false,
                  Description: regular.Description,
                  Type: regular.Type,
                },
                Monthly: {
                  Cost: "",
                  Overage: false,
                  Description: regular.Description,
                  Type: regular.Type,
                },
                Overage: { Cost: "", Overage: true, Description: "", Type: "" },
                prod: { Type: regular.Type, Description: regular.Description },
              });
            }
          });
          return acc;
        },
        []
      );

      setProductsAdded((prevProductsAdded) => [
        ...prevProductsAdded,
        ...newListOfProductsAdded,
      ]);

      editPayloadObject.Services.forEach((service) => {
        service.Regular.forEach((regular) => {
          const productIndex = newListOfProductsAdded.findIndex(
            (product) => product.prod.Type === regular.Type
          );

          const index =
            productIndex === -1
              ? newListOfProductsAdded.length - 1
              : productIndex;
          handleEditProductAdded(index, {
            Type: regular.Type,
            Description: regular.Description,
          });
          handleSetRegularPrice(index, regular.Cost, service.Frequency);
          service.Overage.forEach((overage) => {
            if (overage.Type === regular.Type) {
              handleSetOveragePrice(index, overage.Cost);
            }
          });
        });
      });
    }
  }, [editPayloadObject]);

  return (
    <Grid
      container
      spacing={2}
      className="OwnerYards-PeriodSettings-grid-container"
    >
      <Grid
        xxl={12}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="OwnerYards-PeriodSettings-question-grid"
      >
        <Typography className="OwnerYards-PeriodSettings-spots-question">
          ¿Which type of spots do you offer?
        </Typography>
        <Typography className="OwnerYards-PeriodSettings-question-description">
          Fill the information of the type of spots you offer
        </Typography>
        <Typography className="OwnerYards-PeriodSettings-question-important">
          Important: You must select at least one type of spot
        </Typography>
      </Grid>
      <Grid
        xxl={12}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        spacing={2}
      >
        {width > 600 && (
          <>
            <Grid
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              className="OwnerYards-PeriodSettings-grid-header-column"
            ></Grid>
            <Grid
              xxl={3}
              xl={3}
              lg={3}
              md={3}
              sm={3}
              className={`OwnerYards-PeriodSettings-grid-header-column ${
                activeColumns?.Daily
                  ? "OwnerYards-PeriodSettings-selected-column"
                  : ""
              }`}
            >
              <Typography className="OwnerYards-PeriodSettings-grid-header-column-text">
                Daily
              </Typography>
            </Grid>
            <Grid
              xxl={3}
              xl={3}
              lg={3}
              md={3}
              sm={3}
              className={`OwnerYards-PeriodSettings-grid-header-column ${
                activeColumns?.Monthly
                  ? "OwnerYards-PeriodSettings-selected-column"
                  : ""
              }`}
            >
              {" "}
              <Typography className="OwnerYards-PeriodSettings-grid-header-column-text">
                Monthly
              </Typography>
            </Grid>
            <Grid
              xxl={3}
              xl={3}
              lg={3}
              md={3}
              sm={3}
              className={`OwnerYards-PeriodSettings-grid-header-column ${
                activeColumns?.Overage
                  ? "OwnerYards-PeriodSettings-selected-column"
                  : ""
              }`}
            >
              {" "}
              <Typography className="OwnerYards-PeriodSettings-grid-header-column-text">
                Overage
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        xxl={12}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        columns={14}
        container
      >
        {productsAdded && productsAdded.length > 0 ? (
          productsAdded.map((product, indexProductsAdded) => (
            <React.Fragment key={indexProductsAdded}>
              <Grid
                xs={3}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                container
                className="OwnerYards-PeriodSettings-product-picker-grid-item"
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Product</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={product.Daily.Type ? product.Daily.Type : ""}
                    label="Product"
                    disabled={
                      product.Daily.Type && typeOfTransaction === "edit"
                        ? true
                        : false
                    }
                  >
                    {listOfProducts.map((product, index) => (
                      <MenuItem
                        key={index}
                        disabled={productsAdded.some(
                          (prod) => prod?.prod?.Type === product.Type
                        )}
                        value={product.Type}
                        onClick={(e) =>
                          handleEditProductAdded(indexProductsAdded, product)
                        }
                      >
                        {product.Description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={`OwnerYards-PeriodSettings-product-grid ${
                  activeColumns.Daily
                    ? "OwnerYards-PeriodSettings-selected-column"
                    : ""
                }`}
              >
                <TextField
                  size="small"
                  fullWidth
                  onBlur={(e) => handleActivateAndDeactivateColumns("")}
                  onFocus={(e) => handleActivateAndDeactivateColumns("Daily")}
                  value={product.Daily.Cost ? product.Daily.Cost : ""}
                  disabled={product?.prod?.Type ? false : true}
                  label="Daily/Regular Price"
                  variant="outlined"
                  onChange={(e) => {
                    handleSetRegularPrice(
                      indexProductsAdded,
                      e.target.value,
                      "Daily"
                    );
                  }}
                />
              </Grid>{" "}
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={`OwnerYards-PeriodSettings-product-grid ${
                  activeColumns.Monthly
                    ? "OwnerYards-PeriodSettings-selected-column"
                    : ""
                }`}
              >
                <TextField
                  size="small"
                  fullWidth
                  onBlur={(e) => handleActivateAndDeactivateColumns("")}
                  onFocus={(e) => handleActivateAndDeactivateColumns("Monthly")}
                  value={product.Monthly.Cost ? product.Monthly.Cost : ""}
                  disabled={product?.prod?.Type ? false : true}
                  label="Month/Regular Price"
                  variant="outlined"
                  onChange={(e) => {
                    handleSetRegularPrice(
                      indexProductsAdded,
                      e.target.value,
                      "Monthly"
                    );
                  }}
                />
              </Grid>{" "}
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={`OwnerYards-PeriodSettings-product-grid ${
                  activeColumns.Overage
                    ? "OwnerYards-PeriodSettings-selected-column"
                    : ""
                }`}
              >
                <TextField
                  size="small"
                  fullWidth
                  onBlur={(e) => handleActivateAndDeactivateColumns("")}
                  onFocus={(e) => handleActivateAndDeactivateColumns("Overage")}
                  value={product?.Overage?.Cost ? product.Overage.Cost : ""}
                  disabled={product?.prod?.Type ? false : true}
                  label="Overage Price"
                  variant="outlined"
                  onChange={(e) => {
                    handleSetOveragePrice(
                      indexProductsAdded,
                      e.target.value,
                      "Overage"
                    );
                  }}
                />
              </Grid>
              {typeOfTransaction !== "edit" && (
                <Grid
                  xs={2}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="OwnerYards-PeriodSettings-product-grid"
                >
                  <IconButton
                    disabled={productsAdded.length === 1}
                    onClick={(e) => {
                      let tempProducts = productsAdded;
                      tempProducts.splice(indexProductsAdded, 1);
                      setProductsAdded([...tempProducts]);
                    }}
                  >
                    <RemoveCircleOutlineIcon className="OwnerYards-PeriodSettings-remove-icon" />
                  </IconButton>
                </Grid>
              )}
            </React.Fragment>
          ))
        ) : (
          <>
            {productsAdded === undefined && (
              <>
                {" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  className="OwnerYards-PeriodSettings-product-grid"
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Product
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product"
                      disabled
                    ></Select>
                  </FormControl>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  className="OwnerYards-PeriodSettings-product-grid"
                >
                  <TextField
                    size="small"
                    fullWidth
                    label="Regular Price"
                    variant="outlined"
                    disabled
                  />
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  className="OwnerYards-PeriodSettings-product-grid"
                >
                  <TextField
                    size="small"
                    fullWidth
                    label="Month/Regular Price"
                    variant="outlined"
                    disabled
                  />
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  className="OwnerYards-PeriodSettings-product-grid"
                >
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                  >
                    <InputLabel>Overage Price</InputLabel>
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Checkbox
                            disabled
                            className="OwnerYards-PeriodSettings-checkbox"
                            size="small"
                          />
                        </InputAdornment>
                      }
                      label="Amount"
                    />
                  </FormControl>
                </Grid>
              </>
            )}{" "}
          </>
        )}{" "}
        {typeOfTransaction !== "edit" && (
          <Grid
            xs={14}
            sm={14}
            md={14}
            lg={14}
            xl={14}
            className="OwnerYards-PeriodSetings-add-icon-grid-item"
          >
            <IconButton onClick={(e) => handleAddItemToProductAdded()}>
              <AddIcon className="OwnerYards-PeriodSettings-add-icon" />
              <Typography className="OwnerYards-PeriodSettings-add-icon-text">
                Add Product
              </Typography>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PeriodDailySettings;
