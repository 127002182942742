// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .OwnerYards-YardCard-paper {
    border-radius: 18px;
    padding: 3% 3% 3% 3%;
}

body .OwnerYards-YardCard-yard-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #22224F;
}

body .OwnerYards-YardCard-label {
    font-size: 60%;
    font-weight: 600;
    color: #22224F;
}

body .OwnerYards-YardCard-value {
    font-size: 80%;
    font-weight: 500;
    color: #22224F;
}

body .OwnerYards-YardCard-Buttons-Grid-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

}

body .OwnerYards-YardCard-Button-Edit {
    background-color: #FC4818;
    border-radius: 7px;
    font-size: 85%;
    width: 100%;
    height: fit-content;
}

body .OwnerYards-YardCard-Button-Delete {
    color: #000;
    border-radius: 7px;
    font-size: 85%;
    width: 100%;
    height: fit-content;
}

body .OwnerYards-YardCard-yard-divider{
    border: 2px solid #22224F;
    border-radius: 50px;
    margin: 10px 0px 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/YardCard/YardCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,qBAAqB;;AAEzB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["body .OwnerYards-YardCard-paper {\n    border-radius: 18px;\n    padding: 3% 3% 3% 3%;\n}\n\nbody .OwnerYards-YardCard-yard-name {\n    font-size: 1.5rem;\n    font-weight: 600;\n    color: #22224F;\n}\n\nbody .OwnerYards-YardCard-label {\n    font-size: 60%;\n    font-weight: 600;\n    color: #22224F;\n}\n\nbody .OwnerYards-YardCard-value {\n    font-size: 80%;\n    font-weight: 500;\n    color: #22224F;\n}\n\nbody .OwnerYards-YardCard-Buttons-Grid-Container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-content: center;\n\n}\n\nbody .OwnerYards-YardCard-Button-Edit {\n    background-color: #FC4818;\n    border-radius: 7px;\n    font-size: 85%;\n    width: 100%;\n    height: fit-content;\n}\n\nbody .OwnerYards-YardCard-Button-Delete {\n    color: #000;\n    border-radius: 7px;\n    font-size: 85%;\n    width: 100%;\n    height: fit-content;\n}\n\nbody .OwnerYards-YardCard-yard-divider{\n    border: 2px solid #22224F;\n    border-radius: 50px;\n    margin: 10px 0px 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
