import { getYardsByScac } from "../../services/YardServices";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { setModelToYards } from "../../models/YardModel";
import YardCard from "../../components/YardCard/YardCard";
import { CircularProgress } from "@mui/material";
import "./YardsSummary.css";
import Cookies from "universal-cookie";
const YardSummary = () => {
  const cookies = new Cookies();
  const [yards, setYards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  function handleFetchYards() {
    setYards([]);
    setLoading(true);
    getYardsByScac(cookies.get("sessionCosmos").scac_code).then((data) => {
      const yards = setModelToYards(data.Data);

      setYards(yards);
      setLoading(false);
    });
  }
  React.useEffect(() => {
    handleFetchYards();
    window.addEventListener("OwnerYardsFetchData", handleFetchYards);
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        {yards &&
          yards.length > 0 &&
          yards.map((yard) => (
            <Grid key={yard.cod} xs={12} sm={12} md={12} lg={12} xl={12}>
              <YardCard yard={yard} />
            </Grid>
          ))}
        {loading && (
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="OwnerYards-YardsSummary-grid-circular-progress"
          >
            <CircularProgress
              thickness={5}
              className="OwnerYards-YardsSummary-circular-progress"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default YardSummary;
